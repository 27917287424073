<template>
  <EuiDialog size="large" :open="open" @onClose="closeDialog">
    <template #header>{{ $t('paraphers.dialogs.addSignatories.title') }}</template>
    <EuiGrid>
      <EuiGridRow>
        <EuiGridColumn>
          <span v-if="selectedSign && !Array.isArray(selectedSign) && Object.keys(selectedSign).length > 0" class="font-bold">
            1 {{ $tc('paraphers.dialogs.addSignatories.signatory', 1) }}
          </span>
          <span v-else-if="Array.isArray(selectedSign) && selectedSign.length > 0" class="font-bold">{{ selectedSign.length }} {{ $tc('paraphers.dialogs.addSignatories.signatory', selectedSign.length) }}</span>
        </EuiGridColumn>
        <EuiGridColumn width="1/3">
          <EuiSearchBar :placeholder="$t('searchBar.placeholder')"
                        v-model.trim="searchInput"
                        @change="searchByNameAndEmail"
                        @search="searchByNameAndEmail"
                        @clear="onClear"
                        clearable
          />
        </EuiGridColumn>
      </EuiGridRow>
    </EuiGrid>
    <EuiTabs @onTabSelection="filterSignersOnType" class="mb-3" :activeTabIndex="tabItemShowedIndex">
      <EuiTabItem :disabled="isTabItemDisabled('externalSignatory')">{{ title }}</EuiTabItem>
      <EuiTabItem :disabled="isTabItemDisabled('internalUser')">{{ $t('paraphers.dialogs.addSignatories.users') }}</EuiTabItem>
      <EuiTabItem :disabled="isTabItemDisabled('socialSignatory')" v-if="can('manageSocialSigners', true)">
        {{ $t('paraphers.dialogs.addSignatories.socialSignature') }}
      </EuiTabItem>
    </EuiTabs>
    <div v-if="filtersExternalSignatory.length || filtersInternalUser.length || filtersSocialSignatory.length">
      <EuiDivider secondary />
      <FiltersBar v-if="tabItemShowed === 'externalSignatory'"
                  secondary
                  :filters="filtersExternalSignatory"
                  :radio-options="availableFilters.status"
                  @clear-filters="clearFilters()"
                  @delete-filter="deleteFilterToCompute"
                  @update-filter="updateFilterToCompute"/>
      <FiltersBar v-else-if="tabItemShowed === 'internalUser'"
                  secondary
                  :filters="filtersInternalUser"
                  :radio-options="availableFilters.status"
                  @clear-filters="clearFilters('internalUser')"
                  @delete-filter="deleteFilterToCompute('internalUser')"
                  @update-filter="updateFilterToCompute('internalUser')"/>
      <FiltersBar v-else-if="tabItemShowed === 'socialSignatory'"
                  secondary
                  :filters="filtersSocialSignatory"
                  :radio-options="availableFilters.status"
                  @clear-filters="clearFilters('socialSignatory')"
                  @delete-filter="deleteFilterToCompute('socialSignatory')"
                  @update-filter="updateFilterToCompute('socialSignatory')"/>
      <EuiDivider secondary />
    </div>
    <EuiAdvancedTable v-model="signToDisplay" :selectedValue.sync="selectedSign">
      <template #tableHead>
        <EuiTableHead v-for="(item, index) in sortedHeaders" :key="`${item.field} ${index}`">
          <div class="eui-u-flex">
          {{ item.name }}
            <CardFilter
                     v-if="item.filterType"
                     :field="item.field"
                     :name="item.name"
                     :filterType="item.filterType"
                     :radio-options="availableFilters.status"
                     @addFilter="addFilter($event)"/>
          </div>
        </EuiTableHead>
        <!-- services -->
        <EuiTableHead v-if="showServiceColumn">
          {{ $t('settings.users.table.services') }}
          <EuiDropdownMenu ref="ddServiceFilter" :closeOnClickInside="false">
            <EuiCardFilter :searchPlaceholder="$t('searchBar.placeholder')"
                           :buttonText="$t('cardFilter.button.filter')"
                           v-model="availableFilters.service.searchFieldValue"
                           @click:search="filterOnColumn(availableFilters.service, availableFilters.service.searchFieldValue); $refs.ddServiceFilter.doClose()"
                           @enter:searchBar="filterOnColumn(availableFilters.service, availableFilters.service.searchFieldValue); $refs.ddServiceFilter.doClose()"
                           @click:filter="filterOnColumn(availableFilters.service, availableFilters.service.searchFieldValue); $refs.ddServiceFilter.doClose()">
            </EuiCardFilter>
            <template #anchor><EuiButton iconOnly color="primary"><EuiIcon name="more_vert"/></EuiButton></template>
          </EuiDropdownMenu>
        </EuiTableHead>
      </template>
      <EuiATableRow :selectable="!singleTypeMode && selectedSignatoryIndex === undefined ? 'multiple': 'single'"
                    autoSelect
                    :disabled="!item.email"
                    slot="tableRow"
                    slot-scope="{ item }">
        <!-- full name -->
        <EuiTableCell>{{ item.fullname }}</EuiTableCell>
        <!-- status -->
        <EuiTableCell v-if="isDeskRhDomain && tabItemShowed !== 'internalUser' && tabItemShowed !== 'socialSignatory'">
          <EuiChip :clickable="false"
                   :closable="false"
                   :text="getEmployeeStatusText(item)"
                   :class="[
                     'bpe-status-chip',
                      { '-agreed': getEmployeeStatusText(item) === $t('signatory.table.status.active') },
                      { '-notAgreed': getEmployeeStatusText(item) === $t('signatory.table.status.inactive') },
                   ]">
          </EuiChip>
        </EuiTableCell>
        <!-- mail -->
        <EuiTableCell v-if="tabItemShowed !== 'socialSignatory' && !item.email" class="eui-u-text-red">
          <EuiIcon name="infosperso" color="error" class="eui-u-mr-1"/>{{ $t('signatory.table.email.message') }}
        </EuiTableCell>
        <EuiTableCell v-if="tabItemShowed !== 'socialSignatory' && item.email">{{ item.email }}</EuiTableCell>
        <!-- group -->
        <EuiTableCell v-if="tabItemShowed === 'internalUser' && !isDeskRhDomain">{{ item.group }}</EuiTableCell>
        <!-- identifiant -->
        <EuiTableCell v-if="tabItemShowed === 'internalUser' && isDeskRhDomain">{{ item.login }}</EuiTableCell>
        <!-- organizations -->
        <EuiTableCell v-if="showOrganizationColumn">{{ displayOrganizations(item) }}</EuiTableCell>
        <!-- services -->
        <EuiTableCell v-if="showServiceColumn">{{ item.service }}</EuiTableCell>
        <!-- social signers authorized users -->
        <EuiTableCell v-if="tabItemShowed == 'socialSignatory'" class="text-right">{{ item.authorizedUsersNames }}</EuiTableCell>
      </EuiATableRow>
      <template #tableEmptyState>
        <p class="es-subtitle">{{ $t('paraphers.dialogs.addSignatories.error.noMatch') }}</p>
      </template>
    </EuiAdvancedTable>
    <EuiPagination v-if="signToDisplay.length && pagination"
                   :value="pagination.current"
                   @change="goToPage"
                   :paginationText="$t('pagination.text')"
                   :totalPages="pagination.total"/>
    <!-- pagination -->
    <template #footer>
      <EuiButton color="primary"
                 variant="raised"
                 @click="addSignatories">
        {{ $t('button.add') }}
      </EuiButton>
    </template>
  </EuiDialog>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import mixin from '../../../mixins/compare';
import CardFilter from '../../../components/table/CardFilter';
import FiltersBar from '../../../components/table/FiltersBar';

export default {
  name: 'AddSignatoriesDialog',
  mixins: [mixin],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    selected: {
      type: [Array, Object],
      required: false,
      default: () => [],
    },
    selectedOrganizations: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectedSignatoryIndex: {
      type: Number,
      required: false,
      default: undefined,
    },
    singleTypeMode: {
      type: Boolean,
      default: false,
    },
    selectedUserType: {
      type: String,
      required: false,
      default: undefined,
    },
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
    forceEnableTabItems: {
      type: Boolean,
      default: false,
    },
    selectedUserId: {
      type: [String],
    },
  },
  components: {
    CardFilter,
    FiltersBar,
  },
  watch: {
    open(value) {
      if (value) {
        if (typeof this.selectedSignatoryIndex !== 'undefined') {
          if (this.selected[this.selectedSignatoryIndex]) {
            this.selectedSign = cloneDeep(this.selected[this.selectedSignatoryIndex]);
            delete this.selectedSign.initials;
          } else {
            this.selectedSign = [];
          }
        } else {
          this.selectedSign = cloneDeep(this.selected);
        }
        this.searchParams = JSON.parse(JSON.stringify(this.storeSearchParams));
        this.searchParams.params = [];
        this.searchParams.params.push({ paramName: 'activeInOrg', paramValue: true });
        if (this.selectedUserType !== undefined) {
          this.tabItemShowed = this.selectedUserType;
        } else {
          this.tabItemShowed = 'externalSignatory';
        }
        this.searchSignatories(this.searchParams);
      }
    },
  },
  data() {
    return {
      filtersExternalSignatory: [],
      filtersInternalUser: [],
      filtersSocialSignatory: [],
      headers: [
        { name: this.$i18n.t('signatory.table.name'), field: 'token', types: ['externalSignatory', 'internalUser'], userType: 'all', filterType: ['search', 'single'] },
        { name: this.$i18n.t('signatory.table.raisonSociale'), field: 'raisonSociale', types: ['socialSignatory'], userType: 'all' },
        { name: this.$i18n.t('signatory.table.authorizedUsers'), field: 'authoritzedUsers', types: ['socialSignatory'], userType: 'all' },
        { name: this.$i18n.t('signatory.table.status'), field: 'activeInOrg', types: ['externalSignatory'], userType: 'deskRh', filterType: ['radio', 'single'] },
        { name: this.$i18n.t('signatory.table.email'), field: 'token', types: ['externalSignatory', 'internalUser'], userType: 'all', filterType: ['search', 'single'] },
        { name: this.$i18n.t('settings.users.table.login'), field: 'login', types: ['internalUser'], userType: 'deskRh' },
        { name: this.$i18n.t('settings.users.table.organizations'), field: 'organizations', types: ['externalSignatory', 'internalUser'], userType: 'all' },
        { name: this.$i18n.t('settings.users.table.group'), field: 'group', types: ['internalUser'], userType: 'edocsign' },
        { name: this.$i18n.t('settings.users.table.services'), field: 'services', types: ['externalSignatory'], userType: 'all' },
      ],
      searchInput: '',
      selectedStatus: '',
      searchParams: {},
      searchParamsExternalSignatory: {},
      searchParamsInternalUser: {},
      searchParamsSocialSignatory: {},
      pagination: undefined,
      signToDisplay: [],
      selectedSign: this.selectedSignatoryIndex ? this.selected[this.selectedSignatoryIndex] : this.selected,
      internalUsers: [],
      socialSignatories: [],
      tabItemShowed: 'externalSignatory',
      availableFilters: {
        status: [
          { type: 'radio', name: 'status', status: this.$i18n.t('signatory.table.status.active'), value: true },
          { type: 'radio', name: 'status', status: this.$i18n.t('signatory.table.status.inactive'), value: false },
        ],
        service: {
          type: 'text', name: 'service', value: '', searchParamName: 'service', searchFieldValue: '',
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      signatories: 'ModuleEdocSign/signatory/signatories',
      storeSearchParams: 'ModuleEdocSign/signatory/searchParams',
      paginationSignatories: 'ModuleEdocSign/signatory/pagination',
      can: 'ModuleEdocSign/auth/can',
    }),
    ...mapState({
      paginationUsers: (state) => state.ModuleEdocSign.settingsUsers.pagination,
      isDeskRhDomain: (state) => state.ModuleEdocSign.auth.domains.isDeskRh,
    }),
    sortedHeaders() {
      const filteredByTabItem = this.headers.filter(header => header.types.find(type => type === this.tabItemShowed));
      let filteredHeaders = [];
      if (this.isDeskRhDomain) {
        filteredHeaders = filteredByTabItem.filter(item => item.userType === 'all' || item.userType === 'deskRh');
      } else {
        filteredHeaders = filteredByTabItem.filter(item => item.userType === 'all' || item.userType === 'edocsign');
      }
      if ((this.tabItemShowed === 'externalSignatory' && this.showServiceColumn) || (this.tabItemShowed === 'internalUser' && !this.isDeskRhDomain)) {
        filteredHeaders.splice(filteredHeaders.findIndex(item => item.field === 'organizations'), 1);
      }
      if (this.tabItemShowed === 'externalSignatory' && this.options && !this.options.signerCreationFormDefineService) {
        filteredHeaders.splice(filteredHeaders.findIndex(item => item.field === 'services'), 1);
      }
      return filteredHeaders;
    },
    tabItemShowedIndex() {
      if (this.tabItemShowed === 'internalUser') {
        return 1;
      } else if (this.tabItemShowed === 'socialSignatory') {
        return 2;
      } else {
        return 0;
      }
    },
    showServiceColumn() {
      if (this.tabItemShowed === 'externalSignatory' && this.options && this.options.signerCreationFormDefineService) {
        return this.options.signerCreationFormDefineService.value === '1';
      }
      return false;
    },
    showOrganizationColumn() {
      return (this.tabItemShowed === 'externalSignatory' && !this.showServiceColumn) || (this.tabItemShowed === 'internalUser' && this.isDeskRhDomain);
    },
    title() {
      return this.isDeskRhDomain ? this.$i18n.t('signatory.tabs.title.employees') : this.$i18n.t('signatory.tabs.title.external');
    },
  },
  methods: {
    ...mapActions({
      getSignatory: 'ModuleEdocSign/signatory/getSignatory',
      getSignatories: 'ModuleEdocSign/signatory/getSignatories',
      getSocialSignSignatories: 'ModuleEdocSign/signatory/getSocialSignSignatories',
      getInternalSignatories: 'ModuleEdocSign/settingsUsers/search',
      getSocialSignatories: 'ModuleEdocSign/settingsUsers/searchSocialSigners',
      getInternalUserGroups: 'ModuleEdocSign/settingsGroups/search',
      resetSearchParams: 'ModuleEdocSign/signatory/resetSearchParams'
    }),
    addFilter(newFilter) {
      this.searchIsOn = true;
      let index = -1;
      let filters = [];
      switch (this.tabItemShowed) {
        case 'externalSignatory':
          filters = this.filtersExternalSignatory;
          break;
        case 'internalUser':
          filters = this.filtersInternalUser;
          break;
        case 'socialSignatory':
          filters = this.filtersSocialSignatory;
          break;
        default:
          return;
      }
      for (let i = 0; i < filters.length; i += 1) {
        if (filters[i].field === newFilter.field) {
          index = i;
        }
      }
      if (newFilter.sort.length > 0) {
        filters.forEach((filter, i) => {
          filter.sort = [];
          if (filter.searchTags.length === 0) {
            filters.splice(i, 1);
          }
        });
      }
      if (index === -1) {
        filters.push(newFilter);
      } else {
        filters.splice(index, 1, newFilter);
      }
      this.computeFilter();
      this.searchSignatories();
    },

    clearFilters() {
      switch (this.tabItemShowed) {
        case 'externalSignatory':
          this.filtersExternalSignatory.forEach((filter) => {
            this.clearSearchParamsFromFilter(filter);
          });
          this.resetPagination();
          this.searchSignatories();
          this.filtersExternalSignatory = [];
          break;
        case 'internalUser':
          this.filtersInternalUser.forEach((filter) => {
            this.clearSearchParamsFromFilter(filter);
          });
          this.resetPagination();
          this.searchSignatories();
          this.filtersInternalUser = [];
          break;
        case 'socialSignatory':
          this.filtersSocialSignatory.forEach((filter) => {
            this.clearSearchParamsFromFilter(filter);
          });
          this.resetPagination();
          this.searchSignatories();
          this.filtersSocialSignatory = [];
          break;
        default:
          console.log('ERROR TAB NOT FOUND');
          break;
      }
    },

    updateFilter(newFilters, computeFilter, getListing) {
      switch (this.tabItemShowed) {
        case 'externalSignatory':
          this.filtersExternalSignatory = newFilters;
          break;
        case 'internalUser':
          this.filtersInternalUser = newFilters;
          break;
        case 'socialSignatory':
          this.filtersSocialSignatory = newFilters;
          break;
        default:
      }
      this.computeFilter();
      this.searchSignatories();
    },

    deleteFilter(field) {
      let filters = [];
      switch (this.tabItemShowed) {
        case 'externalSignatory':
          filters = this.filtersExternalSignatory;
          break;
        case 'internalUser':
          filters = this.filtersInternalUser;
          break;
        case 'socialSignatory':
          filters = this.filtersSocialSignatory;
          break;
        default:
          return;
      }
      const index = filters.findIndex((filter) => filter.field === field);
      this.clearSearchParamsFromFilter(filters[index]);
      this.resetPagination();
      this.searchSignatories();
      filters.splice(index, 1);
    },
    computeFilter() {
      this.resetPagination();
      let filters = [];
      switch (this.tabItemShowed) {
        case 'externalSignatory':
          filters = this.filtersExternalSignatory;
          break;
        case 'internalUser':
          filters = this.filtersInternalUser;
          break;
        case 'socialSignatory':
          filters = this.filtersSocialSignatory;
          break;
        default:
          return;
      }
      filters.forEach((filter) => {
        filter.searchTags.forEach((searchTag) => {
          const paramIndex = this.searchParams.params.findIndex(param => param.paramName === filter.field);
          if (paramIndex < 0) {
            this.searchParams.params.push({ paramName: filter.field, paramValue: searchTag });
          } else {
            this.searchParams.params[paramIndex] = { paramName: filter.field, paramValue: searchTag };
          }
        });
        if (filter.sort.length > 0) {
          this.searchParams.orders[0] =
            {
              fieldName: filter.field,
              orderBy: filter.sort[0],
            };
        }
      });
    },

    clearSearchParamsFromFilter(filter) {
      this.searchParams.allowToFilterByDate = false;
      if (filter.sort.length > 0) {
        this.searchParams.orders = [{ fieldName: 'creationDate', orderBy: 'DESC' }];
      }
      if (filter.field === 'creationDate') {
        this.searchParams.params = this.searchParams.params.slice(0, 1);
      } else {
        this.searchParams.params.splice(this.searchParams.params.findIndex(param => param.paramValue === filter.searchTags[0]), 1);
      }
    },

    updateFilterToCompute(newFilters) {
      this.updateFilter(newFilters, this.computeFilter, this.searchSignatories);
    },

    deleteFilterToCompute(field) {
      this.deleteFilter(field);
    },

    resetPagination() {
      this.searchParams.offset = 0;
      this.currentPage = 1;
    },

    isTabItemDisabled(type) {
      if (this.forceEnableTabItems) {
        return false;
      }
      return this.singleTypeMode === true && this.selectedUserType !== type;
    },

    displayOrganizations(item) {
      if (this.tabItemShowed === 'externalSignatory' && !this.showServiceColumn) {
        return item.organizations && item.organizations.length > 0 ? item.organizations.map((value) => value.name).join(', ') : '';
      } if (this.tabItemShowed === 'internalUser' && this.isDeskRhDomain) {
        return item.organizations[0].name;
      }
    },

    onClear() {
      this.searchInput = '';
      this.searchParams.orders = [{ fieldName: 'fullname', orderBy: 'asc' }];
      this.searchParams.params = [];
      this.searchSignatories();
    },

    filterOnColumn(filter, filterValue) {
      filter.value = filterValue;
      this.addFilter(filter);
    },

    searchByNameAndEmail() {
      this.searchParams.orders = [{ fieldName: 'fullname', orderBy: 'asc' }];
      this.searchParams.params = [{
        paramName: 'token', paramValue: this.searchInput,
      }];
      this.searchParams.offset = 0;
      this.searchSignatories();
    },

    searchSignatories() {
      if (this.tabItemShowed === 'externalSignatory') {
        this.searchParams.params = this.searchParams.params.filter((param) => param.paramName !== 'organizationIds');
        if (this.selectedOrganizations && this.selectedOrganizations.length > 0) {
          this.searchParams.params.push({ paramName: 'organizationIds', paramValue: this.selectedOrganizations });
        }
        this.getSignatories(this.searchParams).then(() => {
          this.signToDisplay = this.signatories.map((signer) => {
            signer.type = 'externalSignatory';
            return signer;
          });
          if (this.selectedUserId !== undefined) {
            this.selectedSign = this.signToDisplay.find(item => item.userId === this.selectedUserId); // cloneDeep(this.selected);
          }
          this.resetSearchParams();
          this.pagination = this.paginationSignatories;
        }).catch(() => {
          this.signToDisplay = [];
          this.pagination = undefined;
        });
      } else if (this.tabItemShowed === 'internalUser') {
        this.searchParams.params = this.searchParams.params.filter((param) => param.paramName !== 'organizationIds');
        if (this.selectedOrganizations && this.selectedOrganizations.length > 0) {
          this.searchParams.params.push({ paramName: 'organizationIds', paramValue: this.selectedOrganizations });
        }
        this.searchInternalUsers().then(() => {
          this.signToDisplay = this.internalUsers.map((signer) => {
            signer.type = 'internalUser';
            return signer;
          });
          if (this.selectedUserId !== undefined) {
            this.selectedSign = this.signToDisplay.find(item => item.userId === this.selectedUserId);
          }
          this.pagination = this.paginationUsers;
        }).catch(() => {
          this.signToDisplay = [];
          this.pagination = undefined;
        });
      } else if (this.tabItemShowed === 'socialSignatory') {
        this.searchSocialSignatoriesExtend().then(() => {
          this.signToDisplay = this.socialSignatories.map((signer) => {
            signer.type = 'socialSignatory';
            return signer;
          });
          if (this.selectedUserId !== undefined) {
            this.selectedSign = this.signToDisplay.find(item => item.userId === this.selectedUserId);
          }
          this.pagination = this.paginationUsers;
        }).catch(() => {
          this.signToDisplay = [];
          this.pagination = undefined;
        });
      }
    },

    filterSignersOnType(selectedTab) {
      switch (this.tabItemShowed) {
        case 'externalSignatory':
          this.searchParamsExternalSignatory = { ...this.searchParams };
          break;
        case 'internalUser':
          this.searchParamsInternalUser = { ...this.searchParams };
          break;
        case 'socialSignatory':
          this.searchParamsSocialSignatory = { ...this.searchParams };
          break;
        default:
          return;
      }
      this.searchInput = '';
      if (!this.searchParams.offset) this.searchParams.offset = 0;
      switch (selectedTab) {
        case 0:
          this.tabItemShowed = 'externalSignatory';
          if (this.searchParams.params.length === 0 && !this.searchParamsExternalSignatory.params) this.searchParams.params.push({ paramName: 'activeInOrg', paramValue: true });
          else this.searchParams = { ...this.searchParamsExternalSignatory };
          break;
        case 1:
          this.tabItemShowed = 'internalUser';
          if (this.searchParamsInternalUser.params) this.searchParams = { ...this.searchParamsInternalUser };
          break;
        case 2:
          this.tabItemShowed = 'socialSignatory';
          if (this.searchParams.params.length && !this.searchParamsSocialSignatory.params) {
            this.searchParams.params = [{ paramName: 'entityType', paramValue: 'socialSigner' }];
          } else this.searchParams = { ...this.searchParamsSocialSignatory };
          break;
      }
      this.searchSignatories();
    },

    addSignatories() {
      if (this.selectedUserType === undefined ||
        (Array.isArray(this.selectedSign) && this.selectedSign.length <= 1) ||
        (!Array.isArray(this.selectedSign) && typeof this.selectedSign === 'object')) {
        if (this.selected[this.selectedSignatoryIndex]) {
          this.selected[this.selectedSignatoryIndex] = this.selectedSign;
        } else {
          this.$emit('update:selected', this.selectedSign);
        }
        this.$emit('change', JSON.parse(JSON.stringify(this.selectedSign)));
        this.closeDialog();
      }
    },

    goToPage(page) {
      this.searchParams.offset = (page - 1) * this.searchParams.limit;
      this.searchSignatories(this.searchParams);
    },

    searchInternalUsers() {
      const users = this.getInternalSignatories(this.searchParams).then((response) => {
        if (response && response.accounts) {
          return response.accounts;
        }
      });
      const groups = this.getInternalUserGroups({ orders: [{ fieldName: 'name', orderBy: 'DESC' }], offset: 0, limit: 1000 }).then((response) => {
        if (response && response.groups) {
          return response.groups;
        }
      });
      return Promise.all([users, groups]).then((values) => {
        if (!this.isDeskRhDomain) {
          for (let i = 0; i < values[0].length; i += 1) {
            values[0][i].group = values[1].find((element) => element.id === values[0][i].userGroupId).name;
          }
        }
        this.internalUsers = values[0];
      });
    },

    searchSocialSignatories() {
      return this.getSocialSignatories(this.searchParams).then((response) => {
        if (response && response.accounts) {
          this.socialSignatories = response.accounts;
        }
      });
    },

    async searchSocialSignatoriesExtend() {
      this.searchParams.orders[0].orderBy = 'ASC';
      const prevLimit = this.searchParams.limit;
      return this.getSocialSignatories(this.searchParams).then(async(response) => {
        if (response && response.accounts) {
          for (const account of response.accounts) {
            this.searchParams.params = [{ paramName: 'socialSignerId', paramValue: account.userId }];
            this.searchParams.limit = 51;
            await this.getSocialSignSignatories(this.searchParams).then(async(responseUsers) => {
              if (!account.authorizedUsersNames) account.authorizedUsersNames = '';
              let count = 0;
              for (const user of responseUsers.accounts) {
                if (count >= this.searchParams.limit - 1) {
                  account.authorizedUsersNames += ', ...';
                  break;
                }
                account.authorizedUsersNames += !account.authorizedUsersNames ? user.fullname : (', ' + user.fullname);
                count++;
              }
            }).finally(() => {
              this.searchParams.limit = prevLimit;
            });
          }
          this.socialSignatories = response.accounts;
        }
      });
    },

    getEmployeeStatusText(item) {
      if (item.organizations.some(x => x.id === this.selectedOrganizations[0] && x.active === true)) {
        return this.$i18n.t('signatory.table.status.active');
      }
      return this.$i18n.t('signatory.table.status.inactive');
    },

    closeDialog() {
      this.$emit('update:open', false);
      this.$emit('closeDialog');
      this.filtersExternalSignatory = [];
      this.filtersInternalUser = [];
      this.filtersSocialSignatory = [];
      this.resetSearchParams();
    },
  },
};
</script>

<style>
  .eui-m-cardFilter {
    z-index: 10;
  }
</style>
