<template>
  <div class='eui-u-flex eui-u-items-center eui-u-ml-6'>
    <EuiHeading class='eui-u-text-blue-grey eui-u-my-2' :level="3" bold>{{ $t('filterBar.title')}}</EuiHeading>
    <EuiIcon class='eui-u-text-blue-grey eui-u-ml-2' name='Filter2' size='medium'/>
    <EuiChipFilter class='eui-u-ml-4'
                   closable
                   v-for='(filter, index) in tempFilters'
                   v-model="searchBarValue"
                   :key="index"
                   :filterName="filter.name"
                   :filterValue="filterValue(filter)"
                   @click:closeFilter="removeFilter(filter.field)"
                   @enter:searchBar="addSearchTag(filter)"
                   @click.native="selectFilter(filter.field)"
                   @click:filter="updateFilter(filter)"
                   :filterButtonText="$t('cardFilter.button.filter')"
                   :searchPlaceholder="isSearchable(filter)">
      <template #cardFilter>
        <EuiList v-if="filter.searchTags.length > 0 && !filter.filterType.includes('date') && !filter.filterType.includes('single')">
          <EuiListItem v-for='(searchTag, index) in filter.searchTags'
                       :key='index'>
            {{ searchTag }}
            <EuiButton class='eui-u-ml-auto' iconOnly color="primary" @click.prevent="deleteSearchTag(searchTag)"><EuiIcon name="delete"/></EuiButton>
          </EuiListItem>
        </EuiList>
        <EuiDivider secondary/>
        <EuiButtonGroup v-if="filter.filterType.includes('sortable')" :initialState="filter.sort" vertical mode="radio" #default="{ state, setState }" @change="setSort">
          <EuiButton :selected="state.includes('ASC') && (filter.sort && filter.sort.includes('ASC'))" @click="setState('ASC')">{{ getFilterType(filter, 'ASC')}}</EuiButton>
          <EuiButton :selected="state.includes('DESC') && (filter.sort && filter.sort.includes('DESC'))" @click="setState('DESC')">{{ getFilterType(filter,'DESC')}}</EuiButton>
        </EuiButtonGroup>
        <div v-if="filter.filterType.includes('date')" class="eui-u-pl-3 eui-u-pb-3 eui-u-pr-3 eui-u-flex eui-u-flex-col">
          <div v-for="(datepicker, index) in datepickerOptions" :key="index" class="eui-u-p -small" style="font-weight:500">{{ datepicker }}
            <EuiInput type="date" v-model="dateFilters[index]" :disabled="checkDateSelected(index)"/>
          </div>
        </div>
        <div v-if="filter.filterType.includes('radio')" class="eui-u-pl-3 eui-u-pb-3 eui-u-pr-3 eui-u-flex eui-u-flex-col">
          <EuiRadio v-for="(radio,index) in radioOptions" :key="index" name="radioFilterbar" :value="radio.value" v-model="selectedStatus" >{{ radio.status }}</EuiRadio>
        </div>
      </template>
    </EuiChipFilter>
    <EuiChip v-if="filters.length > 0"
             class='eui-u-ml-4'
             :text="$t('filterBar.button.reset')"
             outlined
             clickable
             @click:chip="$emit('clear-filters')"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'FiltersBar',
  data() {
    return {
      tempFilters: this.filters,
      tempFilter: undefined,
      searchBarValue: undefined,
      datepickerOptions: ['Date', 'Début', 'Fin'],
      dateFilters: [],
      selectedStatus: undefined,
      selectedOrg: '',
    };
  },
  computed: {
    shouldDelete() {
      return this.tempFilter.searchTags.length === 0 && this.tempFilter.sort.length === 0;
    },
    ...mapGetters({
      organizations: 'organizations/organizations',
    }),
  },
  props: {
    filters: {
      type: [Array, Boolean],
      default: () => [],
    },
    radioOptions: Array,
  },
  watch: {
    filters() {
      if (this.filters.length && this.filters[0].searchTags.length) {
        if (this.filters[0].searchTags[0].creationDateStart === this.filters[0].searchTags[0].creationDateEnd) {
          this.dateFilters = [this.filters[0].searchTags[0].creationDateStart];
        } else {
          this.dateFilters = [undefined, this.filters[0].searchTags[0].creationDateStart, this.filters[0].searchTags[0].creationDateEnd];
        }
      }
      this.tempFilters = JSON.parse(JSON.stringify(this.filters));
      this.tempFilters.forEach((filter, index) => {
        if (!filter.searchTags.length && !filter.sort.length) {
          this.tempFilters.splice(index, 1);
        }
      });
    },
    selectedOrg() {
      this.tempFilters.forEach((filter) => {
        if (filter.field === 'organizations') {
          filter.searchTags[0] = this.selectedOrg;
        }
      });
    },
  },
  methods: {
    isSearchable(filter) {
      if (filter.filterType.includes('date') || filter.filterType.includes('radio')) {
        return '';
      }
      return this.$i18n.t('cardFilter.searchBar.placeholder');
    },
    checkDateSelected(index) {
      if (index === 0) {
        if (this.dateFilters.length > 1) {
          return true;
        }
      } else if (this.dateFilters[0] !== undefined) {
        return true;
      }
      return false;
    },

    getFilterType(filter, sort) {
      if (sort === 'ASC') {
        if (filter.filterType.includes('date')) {
          return this.$i18n.t('cardFilter.sort.number.increasing');
        }
        return this.$i18n.t('cardFilter.sort.alphabetical.increasing');
      }
      if (sort === 'DESC') {
        if (filter.filterType.includes('date')) {
          return this.$i18n.t('cardFilter.sort.number.decreasing');
        }
        return this.$i18n.t('cardFilter.sort.alphabetical.decreasing');
      }
      return null;
    },

    filterValue(filter) {
      let value;
      if (filter.searchTags.length > 1 || (filter.searchTags.length > 0 && filter.sort.length > 0)) value = 'Multiple';
      else if (filter.searchTags.length === 0 && filter.sort.length > 0) value = filter.sort[0];
      else if (filter.searchTags.length === 1) {
        if (filter.filterType.includes('date')) {
          if (filter.searchTags[0].date !== undefined) {
            value = this.$i18n.d(new Date(filter.searchTags[0].date), 'short');
          } else if (filter.searchTags[0].creationDateStart !== undefined && filter.searchTags[0].creationDateEnd === undefined) {
            value = `${this.$i18n.d(new Date(filter.searchTags[0].creationDateStart), 'short')} au ${this.$i18n.d(new Date(), 'short')}`;
          } else if (filter.searchTags[0].creationDateStart === undefined && filter.searchTags[0].creationDateEnd !== undefined) {
            value = `jusqu'au ${this.$i18n.d(new Date(filter.searchTags[0].creationDateEnd), 'short')}`;
          } else {
            value = `${this.$i18n.d(new Date(filter.searchTags[0].creationDateStart), 'short')} au ${this.$i18n.d(new Date(filter.searchTags[0].creationDateEnd), 'short')}`;
          }
        } else if (filter.field === 'organizations' || filter.field === 'senderName') {
          const org = this.organizations.find((x) => x.id === filter.searchTags[0]);
          value = org.name;
          filter.searchTags[0] = org.name;
        } else if (filter.filterType.includes('radio')) {
          value = this.radioOptions.find(radio => radio.value === filter.searchTags[0]).status;
          this.selectedStatus = filter.searchTags[0];
        } else {
          value = filter.searchTags[0];
        }
      }
      return value;
    },

    selectFilter(field) {
      if (this.tempFilter === undefined || field !== this.tempFilter.field) {
        this.tempFilters = JSON.parse(JSON.stringify(this.filters));
        this.tempFilter = this.tempFilters.find((filter) => filter.field === field);
      }
    },

    addSearchTag(filter) {
      this.tempFilter.searchTags = [this.searchBarValue];
      if (filter.filterType.includes('single')) {
        this.updateFilter(filter);
      }
      this.searchBarValue = undefined;
    },

    deleteSearchTag(searchTag) {
      const index = this.tempFilter.searchTags.indexOf(searchTag);
      this.tempFilter.searchTags.splice(index, 1);
      if (!this.tempFilter.sort.length) {
        this.removeFilter(this.tempFilter.field);
      }
    },

    setSort(sort) {
      this.tempFilter.sort = sort;
    },

    updateFilter(actualFilter) {
      if (actualFilter.field === 'creationDate') {
        this.tempFilter.searchTags = [];
        if (this.dateFilters[0] !== undefined) {
          this.tempFilter.searchTags.push({ creationDateStart: this.dateFilters[0], creationDateEnd: this.dateFilters[0] });
        } else {
          this.tempFilter.searchTags.push({ creationDateStart: this.dateFilters[1], creationDateEnd: this.dateFilters[2] });
        }
      } else if (actualFilter.filterType.includes('radio')) {
        this.tempFilter.searchTags = [this.selectedStatus];
      } else if (actualFilter.field === 'organizations') {
        const org = this.organizations.find((x) => x.name === this.selectedOrg);
        this.tempFilter.searchTags = [org.id];
      }
      if (this.shouldDelete) {
        this.removeFilter(this.tempFilter.field);
      } else {
        if (this.tempFilter.sort.length > 0) {
          this.tempFilters.forEach((filter, index) => {
            if (filter.field !== this.tempFilter.field) {
              if (filter.searchTags.length === 0) {
                this.tempFilters.splice(index, 1);
              } else {
                filter.sort.splice(0);
              }
            }
          });
        }
        this.$emit('update-filter', this.tempFilters);
      }
      this.tempFilter = undefined;
    },

    removeFilter(field) {
      this.$emit('delete-filter', field);
    },
  },
};
</script>
