<template>
  <EuiDropdownMenu ref="menu" :closeOnClickInside="false">
    <EuiCardFilter :searchPlaceholder="isSearchable"
                   :buttonText="$t('cardFilter.button.filter')"
                   v-model="searchBarValue"
                   @enter:searchBar="addFilter"
                   @click:filter="addFilter">
      <EuiList v-if="searchTags.length > 0">
        <EuiListItem v-for='(searchTag, index) in searchTags'
                     :key='index'
                     style="font-size: 14px; font-weight: 500">
          {{ searchTag }}
          <EuiButton class='eui-u-ml-auto' iconOnly color="primary" @click="deleteSearchTag(searchTag)"><EuiIcon name="delete"/></EuiButton>
        </EuiListItem>
      </EuiList>
      <EuiDivider secondary v-if="searchTags.length > 0"/>
      <EuiButtonGroup style="z-index: 1" v-if="filterType.includes('sortable')" vertical mode="radio" #default="{ state, setState }" @change="setSort">
        <EuiButton :selected="state.includes('ASC')" @click="setState('ASC')">{{ $t('cardFilter.sort.alphabetical.increasing') }}</EuiButton>
        <EuiButton :selected="state.includes('DESC')" @click="setState('DESC')">{{ $t('cardFilter.sort.alphabetical.decreasing') }}</EuiButton>
      </EuiButtonGroup>
      <div class="eui-u-flex eui-u-flex-col eui-u-p-3" v-if="filterType.includes('radio')">
        <EuiRadio v-for="(radio,index) in radioOptions" :key="index" name="radioCardfilter" :value="radio.value" v-model="selectedStatus" >{{ radio.status }}</EuiRadio>
      </div>
    </EuiCardFilter>
    <template #anchor><EuiButton iconOnly color="primary"><EuiIcon name="more_vert"/></EuiButton></template>
  </EuiDropdownMenu>
</template>

<script>
export default {
  name: 'CardFilter',
  data() {
    return {
      searchBarValue: undefined,
      searchTags: [],
      sort: [],
      isMenuOpen: false,
      selectedStatus: true,
    };
  },
  props: {
    filterType: {
      type: [Array, Boolean],
      default: () => [],
    },
    field: {
      type: [String, Object],
    },
    name: {
      type: String,
    },
    radioOptions: Array,
    moveColumns: Boolean,
  },
  computed: {
    isSearchable() {
      return this.filterType.includes('radio') ? '' : this.$i18n.t('cardFilter.searchBar.placeholder');
    },
  },
  methods: {
    addFilter() {
      // do not allow empty string filters
      if (this.searchBarValue === undefined ||
          this.searchBarValue === null ||
          this.searchBarValue === '') return;

      if (this.searchBarValue) {
        this.searchTags.push(this.searchBarValue);
      } else {
        this.searchTags.push(this.selectedStatus);
      }

      this.$emit('addFilter', {
        field: this.field,
        name: this.name,
        filterType: this.filterType,
        searchTags: this.searchTags,
        sort: this.sort,
      });
      this.searchTags = [];
      this.sort = [];
      this.$refs.menu.doClose();
    },
    setSort(sort) {
      this.sort = sort;
    },
  },
};
</script>
